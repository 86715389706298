import { api } from ":mods";
import { ENDPOINTS } from "../const";
import { StageListDetails } from "../models";

export async function getStagesList() {
  return api.getAuth(ENDPOINTS.get_stages_list).then((res) => res.data);
}

export async function getStageListDetails(stageID: number | string): Promise<StageListDetails> {
  if (stageID === undefined) return undefined;
  return api
    .getAuth(ENDPOINTS.stage_listdetails_with_stage_id + stageID) // + "/?page_builder=0")
    .then((res) => res.data);
}
